<template>
    <div>
        <div v-if="modalItem" @click="modalItem=false" style="position:fixed;width:100vw;height:100vh;left:0;top:0;z-index:100;background:rgba(7, 6, 6, 0.671);"></div>
        <div v-if="modalItem" style="position: fixed;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);z-index:105;min-width:50vw;">
        <div class="rounded-lg shadow p-3 bg-white animate__animated animate__fadeIn">
            <p v-if="listItem[0].uang">Uang Pelanggan : Rp. {{listItem[0].uang}}</p>
            <p class="font-semibold">List item</p>
            <div class="table-responsive" v-if="listItem.length>0">
            <table class="table table-sm table-striped table-bordered">
                <tr>
                <td>id Produk</td>
                <td>Nama Produk</td>
                <td>Harga</td>
                <td>Jumlah</td>
                <td>Diskon</td>
                <td>Total</td>
                </tr>
                <tr v-for="(item, index) in listItem" :key="index">
                    <td>{{item.id_produk}}</td>
                    <td>{{item.nama_produk}}</td>
                    <td>Rp. {{item.harga}}</td>
                    <td>{{item.jumlah}}</td>
                    <td>{{item.diskon}}</td>
                    <td>Rp. {{(parseInt(item.jumlah)*parseInt(item.harga))-((parseInt(item.jumlah)*parseInt(item.harga))*(parseInt(item.diskon)/100))}}</td>
                </tr>
            </table>
            </div>
            <div v-else>
                <p class="text-center">Data Kosong</p>
            </div>
        </div>
        </div>
        <div v-if="modalGambar" @click="modalGambar=false" style="position:fixed;width:100vw;height:100vh;left:0;top:0;z-index:100;background:rgba(7, 6, 6, 0.671);"></div>
        <div v-if="modalGambar" style="position: fixed;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);z-index:105;min-width:50vw;">
        <div class="rounded-lg shadow p-3 bg-white animate__animated animate__fadeIn">
            <div class="sm-form p-3" v-if="['dokter','facial','nurse'].includes($store.state.users.level)">
                <label for="gambar1">Upload Gambar</label>
                <input type="file" id="gambar1" name="gambar1" class="form-control p-2 rounded-lg shadow" placeholder="gambar1" >
                <button type="button" @click="upload1" class="btn btn-sm btn-dark mt-2 text-xs">Upload Gambar <span class="typcn typcn-image"></span></button>
                <hr class="my-2">
            </div>
            <p class="font-semibold">List Gambar</p>
            <div class="p-3 shadow row" v-if="listGambar.length>0">
                <div v-for="(item, index) in listGambar" :key="index+'listGambar'" class="col-sm-2">
                    <img 
                    class="img-thumbnail ml-2 mt-2 d-inline" 
                    :src="item.gambar" alt="" style="height:150px;" v-viewer>
                    <button type="button" class="btn btn-sm btn-danger my-2 text-xs" 
                    v-if="['dokter','facial','nurse'].includes($store.state.users.level)"
                    @click="hapusGambar(item)">Delete <span class="typcn typcn-delete"></span></button>
                </div>
            </div>
            <div v-else>
                <p class="text-center">Data gambar kosong</p>
            </div>
        </div>
        </div>
        <div class="row justify-content-center">
           <div class="col-12 p-4">
               <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-style4 text-xs"> <span class="typcn typcn-arrow-left"></span> Back</button>
           </div>
          <div class="col-md-11" style="min-height:100vh;">
              <!-- <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-dark  my-3">Back</button> -->
              <!-- <div class="sm-form ">
                  <label for="search">Cari tanggal</label>
                  <button type="button" class="btn ml-3 btn-sm btn-dark  " @click="pencarian=''"><span class="typcn typcn-arrow-loop"></span></button>
                  <input type="date" style="width:300px;" id="search" name="search" class="form-control p-2 rounded-lg shadow" placeholder="search" v-model="pencarian" >
              </div> -->
              <div class="row">
                  <div class="col-6">
                      <input type="text" id="pencarian" name="pencarian" class="form-control p-2 rounded-lg shadow" placeholder="pencarian nota" v-model="pencarian"  >
                  </div>
                  <div class="col-6">
                      <input type="date" id="pencariantgl" name="pencariantgl" class="form-control p-2 rounded-lg shadow" placeholder="pencariantgl" v-model="pencariantgl"  >
                  </div>
              </div>
              <hr class="my-2">
              <div class="shadow rounded-lg p-3" v-if="pelanggan.length>0">
                    <hr class="my-2">
                    <div v-if="td.length>0">
                            <div v-for="(item, index) in td" :key="index+'history'" class="my-2 shadow rounded p-3">
                                <div class="row">
                                <div class="col-12 py-3">
                                    <p class="text-lg float-right" >Tanggal : <span class="typcn typcn-calendar"></span> {{format(item.tanggal)}}</p>
                                    <button type="button" class="btn btn-sm btn-dark">{{index+1}}</button>
                                    <hr class="my-2">
                                </div>
                                <div class="col-6">
                                    <div class="shadow p-3 rounded">
                                        <p class="text-lg font-semibold">Staff yang menangani : </p>
                                        <p class="text-xs font-semibold">{{item.id_transaksi_nota}}</p>
                                        <hr class="my-2">
                                        <table>
                                            <tr v-if="item.dokter">
                                                <td>Dokter</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.dokter}}</td>
                                            </tr>
                                            <tr v-if="item.nurse">
                                                <td>Nurse</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.nurse}}</td>
                                            </tr>
                                            <tr v-if="item.facial">
                                                <td>Facial Therapist</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.facial}}</td>
                                            </tr>
                                            <tr v-if="item.apoteker">
                                                <td>Apoteker</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.apoteker}}</td>
                                            </tr>
                                            <tr v-if="item.admin">
                                                <td>Frontliner</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.admin}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="shadow p-3 rounded font-semibold">
                                        <p class="text-xs">Pasien: {{pelanggan[0].nama}}</p>
                                        <p class="text-xs" >Tanggal : {{format(item.tanggal)}}</p>
                                        <hr class="my-3">
                                    </div>
                                    <div class="text-right">
                                        <button type="button" @click="showGambar(item)" class="btn btn-sm btn-style2 mt-2 ml-2 mr-2 text-xs"><span class="typcn typcn-group"></span> Show Gambar</button>
                                        <button type="button" @click="showItem(item)" class="btn btn-sm btn-style2 mt-2 ml-2 text-xs"><span class="typcn typcn-eye"></span> Show Item Transaksi</button>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-3">
                            <div class="row">
                            <div class="col-md-6 " >
                                <div class="shadow p-2 rounded-lg">
                                    <hr class="my-2">
                                    <div class="sm-form">
                                        <div>
                                            <div v-if="item.keluhan">
                                                <p>Keluhan : </p>
                                                <p v-text="item.keluhan" class="font-semibold text-xs pl-2"></p>
                                                <hr class="my-2">
                                            </div>
                                            <div v-if="item.objective">
                                            <p>Objective : </p>
                                            <p v-text="item.objective" class="font-semibold text-xs pl-2"></p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="col-md-6 shadow p-2 rounded-lg" >
                                <div class="shadow p-2 rounded-lg">
                                    <hr class="my-2">
                                    <div>
                                        <div v-if="item.diagnosis">
                                            <p class="">Diagnosis : </p>
                                            <p v-text="item.diagnosis" class="font-semibold text-xs pl-2"></p>
                                            <hr class="my-2">                           
                                        </div>
                                        <div v-if="item.plan">
                                            <p class="">Plan : </p>
                                            <p v-text="item.plan" class="font-semibold text-xs pl-2"></p>
                                            <hr class="my-2">
                                        </div>
                                        <div v-if="item.catatan_review">
                                            <p>Catatan Review: </p>
                                            <p v-text="item.catatan_review" class="font-semibold text-xs pl-2"></p>
                                            <hr class="my-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <hr class="my-3">
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-6" v-if="item.catatan_admin">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Frontliner</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div class="text-xs" v-html="item.catatan_admin"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_dokter">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Dokter</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div class="text-xs" v-html="item.catatan_dokter"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_nurse">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nurse</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div class="text-xs" v-html="item.catatan_nurse"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_facial">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Facial Therapist</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div class="text-xs" v-html="item.catatan_facial"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_apoteker">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Apoteker</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div class="text-xs" v-html="item.catatan_apoteker"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <hr>
                        <p class="p-5 text-center">Catatan Kosong </p>
                    </div>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
let sdb = new autophp();
export default{
    data(){
        return{
            modalGambar:false,
            modalItem:false,
            listItem:[],
            listGambar:[],
            pelanggan:[],
            history:[],
            transaksi:[],
             dokter:[],
            nurse:[],
            adminapotek:[],
            apoteker:[],
            notanya:'',
            pencarian:'',
            pencariantgl:'',
        }
    },
    computed:{
        td(){
            let that=this;
            let data=this.history;
            data=data.filter(e=>{
                if(e.id_transaksi_nota.indexOf(that.pencarian)!=-1 && e.tanggal.indexOf(that.pencariantgl)!=-1){
                    return e;
                }
            })
            console.log(data)
            return data;
        }
    },
    methods: {
         async upload1(){
            let that=this;
            if(!confirm('Apakah upload photo ini?')){
                return;
            }
            sdb.loadingOn();
            let file = document.querySelector("#gambar1"); // berikan id pada input file
            if(file.files.length>0){
                sdb.collection('app_kasir_pelanggan_gambar').upload(file,true,true) // file,compress,scramble
                .then(async (res)=>{
                    let data={
                        gambar:res,
                        nota:that.notanya.id_transaksi_nota
                    }
                    await sdb.collection('app_kasir_pelanggan_gambar').doc().set(data);
                    sdb.alert('Berhasil upload gambar','bg-green-400');
                    that.showGambar(that.notanya);
                    sdb.loadingOff();
                })
            }else{
                    sdb.loadingOff();
                sdb.alert('Photo tidak ada!');
            }
        },
        async hapusGambar(item){
            console.log(item.gambar.split('/')[item.gambar.split('/').length-1])
            if(!confirm('Apakah yakin menghapus gambar ini?')){
                return;
            }
            // await sdb.collection("app_kasir_pelanggan_gambar").doc().select(`select * from app_kasir_pelanggan_gambar where nota='${item.id_transaksi_nota}'`)
            sdb.collection("app_kasir_pelanggan_gambar").doc(item.id).delete().then(res => {
                sdb.alert('Berhasil menghapus gambar!');
            });
            let fd=new FormData();
            fd.append('nama_file',item.gambar.split('/')[item.gambar.split('/').length-1]);
           await axios.post('https://apis2.draesthetic.id/hapusgambar.php',fd).then(res=>{})
            this.modalGambar=false;
            this.$forceUpdate();
        },
         async showGambar(item){
            this.modalGambar=true;
            this.notanya=item;
            this.listGambar=await sdb.collection("app_kasir_pelanggan_gambar").doc().select(`select * from app_kasir_pelanggan_gambar where nota='${item.id_transaksi_nota}'`)
            this.$forceUpdate();
        },
         async showItem(item){
             console.log(item)
            this.modalItem=true;
            this.listItem = await sdb.collection("app_kasir_keranjang",false).doc().select(`select tb1.*,tb2.nama_produk,tb2.harga ,tb3.uang
            from app_kasir_keranjang tb1 
            left join app_kasir_produk tb2 on tb2.id=tb1.id_produk 
            left join app_kasir_transaksi_nota tb3 on tb3.nota=tb1.nota 
            where tb1.nota='${item.id_transaksi_nota}'
            group by tb1.id
            `)
            this.$forceUpdate();
        },
        format(tgl) {
            return this.$datefns.format(
                new Date(tgl),
                "DD-MM-YYYY"
            );
        },
        getData(){
            let that=this;
            let sql =`select 
            tb1.*,tb2.id_admin,tb2.id_dokter,tb2.id_apoteker,tb2.id_nurse,
            tb2.catatan_admin,tb2.catatan_apoteker,tb2.catatan_dokter,tb2.catatan_facial,tb2.catatan_nurse,
            tb3.nama as admin,
            tb4.nama as dokter,
            tb5.nama as nurse,
            tb6.nama as apoteker,
            tb7.nama as facial
            from app_kasir_pelanggan_history tb1 
            left join app_kasir_transaksi_nota tb2 on tb2.nota=tb1.id_transaksi_nota
            left join tbuser tb3 on tb3.id=tb2.id_admin 
            left join tbuser tb4 on tb4.id=tb2.id_dokter 
            left join tbuser tb5 on tb5.id=tb2.id_nurse 
            left join tbuser tb6 on tb6.id=tb2.id_apoteker 
            left join tbuser tb7 on tb7.id=tb2.id_facial 
            where tb1.pelanggan='${this.$route.query.pelanggan}' AND tb1.catatan IS NOT NULL
            group by tb1.id_transaksi_nota
            `;
            console.log(sql)
            sdb.collection("app_kasir_pelanggan_history",false).doc().select(sql).then(res => {
                console.log('history',res)
                this.history=res;
                this.history= _.orderBy(this.history, ['tanggal'],['desc']);
                this.$forceUpdate();
            });
            sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id='${this.$route.query.pelanggan}'`).then(res => {
                this.pelanggan=res;
                this.$forceUpdate();
            });
        }
    },
    layout:'app_shop_admin',
    mounted() {
        this.getData();
    },
}
</script>